import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { Notification } from 'element-ui';

export class AdReportForms {
  @Inject(HttpService) private http!: HttpService;

  //导出
  public exportReport(param: Dict<any>, filename: any): Promise<any> {
    return this.http.downloadFile('/wecom-bsp-backend/admin/reportFrom/adExportReport', param, filename);
  }

  //列表
  public reportFromList(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/reportFrom/adReportFromList', param);
  }

  //france下拉框
  public franchise(): Promise<any> {
    return this.http.get('/wecom-bsp-backend/admin/pcDropDownList/franchise');
  }
}
